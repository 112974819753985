
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";

export default defineComponent({
  props: {
    today: {
      type: String,
    },
  },
  setup(props) {
    const form = ref({});

    const varieties = ref([]);
    const customers = ref([]);

    const search = ref("");

    const processing = ref(false);

    const len = ref(3);

    const day = ref(0);
    const date = ref(new Date());

    function friday() {
      const f = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return f.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(f.setDate(f.getDate() - (f.getDay() - 1) + 4));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = new Date(d);
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    async function fetchVarietyOrders(d: any) {
      const res = await http.get(
        `/api/variety-orders?date=${fD(d, "yyyy-MM-dd")}`
      );

      return res.data;
    }

    function fetchVarieties(d: any) {
      processing.value = true;

      fetchVarietyOrders(d).then((av: any) => {
        const os = av.orders;
        const ts = av.tpicks;
        const ps = av.picks;
        customers.value = av.customers;

        len.value = Object.entries(av.customers || { 1: 0 }).length + 1;

        http.get(`/api/varieties?fetch=all`).then((res) => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                orders: (os as any)[v.id] || 0,
                today: (ts as any)[v.id] || {},
                picks: (ps as any)[v.id] || 0,
              },
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function searchVarieties(d: any) {
      fetchVarietyOrders(d).then((av: any) => {
        const os = av.orders;
        const ps = av.picks;

        const a = varieties.value.filter((v: any) => {
          const name =
            search.value.charAt(0).toUpperCase() + search.value.slice(1);
          return v.name?.startsWith(name);
        });

        varieties.value = a;

        form.value = a.reduce(
          (r: any, v: any) => ({
            ...r,
            [v.id]: {
              orders: (os as any)[v.id] || 0,
              picks: (ps as any)[v.id] || 0,
            },
          }),
          {}
        );
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function sumTotal(o: any) {
      const data = Object.values(o);

      return data.reduce((a: any, b: any) => {
        return a + parseFloat(b);
      });
    }

    function isFilled(o: any) {
      const data = Object.values(o);

      const filled = data.filter((f: any) => {
        return Number(f) > 0;
      });

      return filled.length > 0;
    }

    onMounted(() => {
      fetchVarieties(start.value);

      date.value = start.value;
    });

    watch(
      () => start.value,
      (s) => {
        fetchVarieties(s);
      }
    );

    watch(
      () => date.value,
      (d) => {
        fetchVarieties(d);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      customers,
      fD,
      setDay,
      start,
      end,
      day,
      date,
      getDates,
      form,
      setKg,
      getPercentage,
      len,
      sumTotal,
      isFilled,
    };
  },
});
